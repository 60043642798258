import 'whatwg-fetch';

type JSONObject = { [k: string]: any };
type AJAXResponse = JSONObject | JSONObject[];

const handleRequestErrors = (response: Response) => {
  if (response.ok) {
    return response;
  } else {
    /*let errorMessage = `${response.status} (${response.statusText})`,
          error = new Error(errorMessage);*/
    throw response;
  }
};

export const DELETE = (
  url: string,
  successCallback: (response: { [k: string]: any }) => void,
  errorCallback: (response: { [k: string]: any }) => void,
  data?: { [k: string]: any }
) => {
  var headers = new Headers();
  headers.set('Content-Type', 'application/json');

  var options: { [k: string]: string | Headers } = {
    credentials: 'same-origin',
    method: 'DELETE',
    headers,
  };
  if (data) options.body = JSON.stringify(data);
  return window
    .fetch(url, options)
    .then(handleRequestErrors)
    .then(response => response.json())
    .then(successCallback)
    .catch(errorCallback);
};

export const GET = (
  url: string,
  successCallback: (response: AJAXResponse) => void,
  errorCallback: (response: { [k: string]: any }) => void
) => {
  var headers = new Headers();

  var resultPromise = window
    .fetch(url, { credentials: 'same-origin', headers })
    .then(handleRequestErrors)
    .then(response => response.json());

  resultPromise.then(successCallback).catch(errorCallback);

  return resultPromise;
};

export const POST = (
  url: string,
  data: { [k: string]: any } | FormData,
  successCallback: (response: { [k: string]: any }) => void,
  errorCallback: (response: { [k: string]: any }) => void,
  isForm: boolean = false
) => {
  var headers = new Headers();

  if (isForm) {
    // headers.set('Content-Type', 'multipart/form-data');
  } else {
    headers.set('Content-Type', 'application/json');
  }

  return window
    .fetch(url, {
      method: 'POST',
      headers: headers,
      credentials: 'same-origin',
      body: isForm ? (data as FormData) : JSON.stringify(data),
    })
    .then(handleRequestErrors)
    .then(response => {
      return response.json();
    })
    .then(successCallback)
    .catch(errorCallback);
};

export const PUT = (
  url: string,
  data: { [k: string]: any },
  successCallback: (response: { [k: string]: any }) => void,
  errorCallback: (response: { [k: string]: any }) => void,
  isForm: boolean = false
) => {
  var headers = new Headers();
  if (isForm) {
    // headers.set('Content-Type', 'multipart/form-data');
  } else {
    headers.set('Content-Type', 'application/json');
  }

  return window
    .fetch(url, {
      method: 'PUT',
      headers: headers,
      // credentials: 'same-origin',
      // body: JSON.stringify(data),
      body: isForm ? (data as FormData) : JSON.stringify(data),
    })
    .then(handleRequestErrors)
    .then(response => response.json())
    .then(successCallback)
    .catch(errorCallback);
};
