import React, { useState } from 'react';
import FormRender from '../../components/formRender/formRender';
import { YencilFormData } from '../../components/formRender/types';
import './login.scss';

function Login() {
  const [data, setData] = useState<YencilFormData>({ email: 'dontmatter' });
  const [isLoggingIn, setIsLoggingIn] = useState<Boolean>(false);

  const loginForm = {
    form: {
      method: 'post',
      action: '/login',
    },
    rows: [
      {
        fields: [
          {
            name: 'email',
            type: 'hidden',
            placeholder: 'Email',
          },
        ],
      },
      {
        fields: [
          {
            name: 'password',
            type: 'password',
            placeholder: 'Password',
            label: 'Password',
          },
        ],
      },
      {
        fields: [
          {
            type: 'submit',
            customClass: 'hover',
            text: isLoggingIn ? 'Attempting log in...' : 'Log In',
            onClick: (data: YencilFormData) => {
              setIsLoggingIn(true);
            },
          },
        ],
      },
    ],
  };

  return (
    <div id='login' className='small-container' style={{ maxWidth: '600px' }}>
      <div className='login-title'>
        <img src='https://yencilstatic.s3.us-east-2.amazonaws.com/blackSmallLogo.png' /> | Metrics
      </div>
      <FormRender data={data} setData={setData} settings={loginForm} />
    </div>
  );
}

export default Login;
