import React, { useCallback, useContext, useEffect, useState } from 'react';
import { User } from '../types';
import { GET } from '../services/ajax';

type YencilContextType = {
  fetchUser: () => void;
  user: User | undefined | null;
  isUserLoaded: boolean;
  setUser: React.Dispatch<User>;
  isTestMode: boolean;
  setIsTestMode: React.Dispatch<boolean>;
};

const YencilContext = React.createContext<YencilContextType>({} as YencilContextType);

export const useYencilContext = () => useContext(YencilContext);

export const YencilContextProvider: React.FC = ({ children }) => {
  const [isUserLoaded, setIsUserLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined | null>(undefined);
  const [isTestMode, setIsTestMode] = useState<boolean>(false);

  const fetchUser = useCallback(() => {
    GET(
      '/api/entity/account/identity',
      user => {
        user && setUser(user as User);
        setIsUserLoaded(true);
      },
      () => {
        setUser(null);
        setIsUserLoaded(true);
      }
    );
  }, []);

  const initialState = {
    fetchUser,
    isUserLoaded,
    user,
    setUser,
    isTestMode,
    setIsTestMode,
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return <YencilContext.Provider value={initialState}>{children}</YencilContext.Provider>;
};
