import React, { useState } from 'react';
import Menu from '../../components/menu/menu';
import Nav from '../../components/nav/nav';
import { MetricsPage } from '../../types';
import GraphSection from '../graphSection/graphSection';
import HomeSection from '../homeSection/homeSection';
import './metrics.scss';

function Metrics() {
  const [page, setPage] = useState<MetricsPage>('home');
  return (
    <div id='home' className=''>
      <Nav />
      <div id='home-content'>
        <Menu page={page} setPage={setPage} />
        <div id='content'>
          {page === 'home' && <HomeSection setPage={setPage} />}
          {page === 'contributions' && <GraphSection page={page} />}
          {page === 'downloads' && <GraphSection page={page} />}
          {page === 'users' && <GraphSection page={page} />}
          {page === 'ambassadors' && <GraphSection page={page} />}
        </div>
      </div>
    </div>
  );
}

export default Metrics;
