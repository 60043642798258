import React, { useState } from 'react';
import clsx from 'clsx';
import { FormRenderProps, FormRenderField, FormRenderRow } from './types';
import './formRender.scss';

function FormRender({ data, settings, setData }: FormRenderProps) {
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const { rows } = settings;

  const valueChanged = (fieldName: string, value: string | string[] | boolean) => {
    setData({ ...data, [fieldName]: value });
  };

  const genField = (field: FormRenderField) => {
    const {
      customClass = '',
      isDisabled,
      multiple = false,
      name,
      onClick,
      options,
      placeholder,
      text,
      type,
    } = field;
    const fieldName = name || 'unknown';
    const dataValue = data[fieldName] as string | number | string[];
    let output;

    if (type === 'textarea') {
      output = (
        <textarea
          className={errors[fieldName] ? 'error' : ''}
          disabled={isDisabled ? isDisabled() : false}
          value={dataValue}
          name={fieldName}
          id={fieldName}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            valueChanged(fieldName, e.target.value);
            field.onChange && field.onChange();
          }}
          placeholder={placeholder || ''}
          // style={{ height: get(field, 'height', 'auto') }}
        ></textarea>
      );
    } else if (type === 'select') {
      output = (
        <select
          name={fieldName}
          id={fieldName}
          className={clsx({ error: errors[fieldName], placeholder: !dataValue })}
          disabled={isDisabled ? isDisabled() : false}
          value={dataValue || (multiple ? [] : '')}
          multiple={!!multiple}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = Array.from(e.target.options)
              .filter(o => o.selected)
              .map(o => o.value);
            valueChanged(fieldName, value);
            field.onChange && field.onChange();
          }}
        >
          {!multiple && (
            <option value='' className={'placeholder'} disabled hidden>
              {placeholder || 'Please Choose...'}
            </option>
          )}
          {options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      );
    } else if (type === 'checkbox') {
      output = (
        <div className='form-field-checkbox-holder' style={{ textAlign: 'right' }}>
          <input
            className={clsx(customClass, { error: errors[fieldName] })}
            disabled={isDisabled ? isDisabled() : false}
            type='checkbox'
            checked={Boolean(dataValue)}
            name={fieldName}
            id={fieldName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              valueChanged(fieldName, e.target.checked);
              field.onChange && field.onChange();
            }}
            placeholder={placeholder || ''}
          />
        </div>
      );

      // DEFAULT
    } else if (type === 'space') {
      output = '';
    } else if (type === 'plaintext') {
      output = <div className={clsx(customClass)}> {text} </div>;
    } else {
      output = (
        <div>
          <input
            className={clsx(customClass, { error: errors[fieldName] })}
            disabled={isDisabled ? isDisabled() : false}
            type={type || 'text'}
            value={type === 'submit' ? text : dataValue}
            name={fieldName}
            id={fieldName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              valueChanged(fieldName, e.target.value);
              field.onChange && field.onChange();
            }}
            placeholder={placeholder || ''}
            onClick={() => onClick && onClick(data)}
          />
        </div>
      );
    }
    return (
      <div key={fieldName} className={`form-field form-field-${field.type}`}>
        {field.label && (
          <label htmlFor={fieldName} style={{ float: field.type === 'checkbox' ? 'left' : 'none' }}>
            {field.label} {field.required && <i className='fa fa-asterisk'></i>}{' '}
          </label>
        )}
        {output}
        {field.info && (
          <div className='form-field-info'>
            {typeof field.info === 'function' ? field.info() : field.info}
          </div>
        )}
      </div>
    );
  };

  const genRow = (row: FormRenderRow, i: number) => {
    return (
      <div className={`row ${row.rowClass}`} key={`row-${i}`}>
        {row.fields.map(genField)}
      </div>
    );
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!settings?.form?.action) {
      e.preventDefault();
    }
  };

  return (
    <div className='form-render-holder'>
      <form
        className='form-render'
        onSubmit={onSubmit}
        action={settings?.form?.action}
        method={settings?.form?.method}
      >
        {rows.map((row, i) => genRow(row, i))}
      </form>
    </div>
  );
}

export default FormRender;
