import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useYencilContext } from '../../state/context';
import Toggler from '../toggler/toggler';
import './nav.scss';

function Nav() {
  const history = useNavigate();
  const { isTestMode, setIsTestMode } = useYencilContext();

  return (
    <nav id='nav'>
      <div className='nav-title flex1'>
        <img src='https://yencilstatic.s3.us-east-2.amazonaws.com/blackSmallLogo.png' /> &nbsp; |
        &nbsp; Metrics
      </div>
      <div className='toggle-section'>
        <div>Test Mode</div>
        <Toggler isOn={isTestMode} onClick={() => setIsTestMode(!isTestMode)} isSmall={true} />
      </div>
    </nav>
  );
}

export default Nav;
