import React, { useState } from 'react';
import ActivityGraph from '../../components/activityGraph/activityGraph';
import GraphManager from '../../components/graphManager/graphManager';
import TableData from '../../components/tableData/tableData';
import { DataField, MetricsPage } from '../../types';

function GraphSection({ page }: { page: MetricsPage }) {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<Record<string, unknown>[]>([]);
  const [dataFields, setDataFields] = useState<DataField[]>([]);
  console.log({ page });

  return (
    <div id='graph-section' className='metrics-section'>
      <GraphManager
        setResults={setResults}
        setLoading={setIsLoading}
        setDataFields={setDataFields}
        type={page}
      />
      <>
        {isLoading && (
          <div style={{ height: '400px' }}>
            <div className='loader'></div>
          </div>
        )}
        {!isLoading && (
          <div style={{ padding: '10px 50px' }}>
            <ActivityGraph rawData={results} dataFields={dataFields} />
          </div>
        )}
      </>
      <TableData type={page} />
    </div>
  );
}

export default GraphSection;
