import React, { useState } from 'react';
import clsx from 'clsx';
import Nav from '../../components/nav/nav';
import { MetricsPage } from '../../types';
import './menu.scss';

const pages = ['Home', 'Contributions', 'Downloads', 'Users', 'Ambassadors'];

function Menu({ page, setPage }: { page: MetricsPage; setPage: React.Dispatch<MetricsPage> }) {
  return (
    <div id='menu'>
      {pages.map(p => (
        <div
          className={clsx('link', { active: page === p.toLocaleLowerCase() })}
          onClick={() => setPage(p.toLocaleLowerCase() as MetricsPage)}
        >
          {p}
        </div>
      ))}
    </div>
  );
}

export default Menu;
