import clsx from 'clsx';
import React from 'react';
import './toggler.scss';

function Toggler({
  isSmall,
  isOn,
  onClick,
}: {
  isSmall: boolean;
  isOn: boolean;
  onClick?: Function;
}) {
  return (
    <div className={clsx('toggler', { small: isSmall, isOn })} onClick={() => onClick && onClick()}>
      <div className='toggler-disc'></div>
    </div>
  );
}

export default Toggler;
