import React, { useEffect, useState } from 'react';
import { POST } from '../../services/ajax';
import { useYencilContext } from '../../state/context';
import { MetricsPage, QueryResponse } from '../../types';

function HomeSectionPanel({
  value,
  isLoading,
  setPage,
  page,
  title,
}: {
  value: number;
  isLoading: boolean;
  title: string;
  setPage: React.Dispatch<MetricsPage>;
  page: MetricsPage;
}) {
  return (
    <div className='home-section-panel' onClick={() => setPage(page)}>
      {isLoading && <div className='loader'></div>}
      {!isLoading && (
        <>
          <div className='value'>{value}</div>
          <div className='title'>{title}</div>
        </>
      )}
    </div>
  );
}

function HomeSection({ setPage }: { setPage: React.Dispatch<MetricsPage> }) {
  const { isTestMode } = useYencilContext();
  const [downloadCount, setDownloadCount] = useState(0);
  const [accountCount, setAccountCount] = useState(0);
  const [contributionCount, setContributionCount] = useState(0);
  const [ambassadorsAppCount, setAmbassadorsAppCount] = useState(0);
  const [isDownloadsLoading, setIsDownloadsLoading] = useState(true);
  const [isAccountsLoading, setIsAccountsLoading] = useState(true);
  const [isAmbassadorsLoading, setIsAmbassadorsLoading] = useState(true);
  const [isContributionsLoading, setIsContributionsLoading] = useState(true);

  useEffect(() => {
    setIsDownloadsLoading(true);
    setIsAccountsLoading(true);
    setIsContributionsLoading(true);

    POST(
      `/api/entity/data/fetch`,
      {
        responseType: 'total',
        eventType: 'downloads',
        isTestMode,
      },
      output => {
        const response = output as QueryResponse;
        setDownloadCount(response.metadata.total);
        setIsDownloadsLoading(false);
        console.log(response);
      },
      () => {
        setIsDownloadsLoading(false);
      }
    );

    POST(
      `/api/entity/data/fetch`,
      {
        responseType: 'total',
        eventType: 'users',
        isTestMode,
      },
      output => {
        const response = output as QueryResponse;
        console.log(response);
        setAccountCount(response.metadata.total);
        setIsAccountsLoading(false);
      },
      () => {
        setIsAccountsLoading(false);
      }
    );

    POST(
      `/api/entity/data/fetch`,
      {
        responseType: 'total',
        eventType: 'contributions',
        isTestMode,
      },
      output => {
        const response = output as QueryResponse;
        console.log(response);
        setContributionCount(response.metadata.total);
        setIsContributionsLoading(false);
      },
      () => {
        setIsAccountsLoading(false);
      }
    );

    POST(
      `/api/entity/data/fetch`,
      {
        responseType: 'total',
        eventType: 'ambassadors',
        isTestMode,
      },
      output => {
        const response = output as QueryResponse;
        setAmbassadorsAppCount(response.metadata.total);
        setIsAmbassadorsLoading(false);
      },
      () => {
        setIsAmbassadorsLoading(false);
      }
    );
  }, [isTestMode]);

  return (
    <div id='home-section' className='metrics-section center'>
      <HomeSectionPanel
        value={downloadCount}
        isLoading={isDownloadsLoading}
        title={'Downloads'}
        page={'downloads'}
        setPage={setPage}
      />
      <HomeSectionPanel
        value={accountCount}
        isLoading={isAccountsLoading}
        title={'Users'}
        page={'users'}
        setPage={setPage}
      />
      <HomeSectionPanel
        value={contributionCount}
        isLoading={isContributionsLoading}
        title={'Contributions'}
        page={'contributions'}
        setPage={setPage}
      />
      <HomeSectionPanel
        value={ambassadorsAppCount}
        isLoading={isAmbassadorsLoading}
        title={'Ambassador Applications'}
        page={'ambassadors'}
        setPage={setPage}
      />
    </div>
  );
}

export default HomeSection;
