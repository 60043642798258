import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { first, last, randomNumber } from '../../services/util';
import { select, pointer } from 'd3-selection';
import { scaleTime, scaleLinear } from 'd3-scale';
import { axisLeft, axisBottom } from 'd3-axis';
import { bisector } from 'd3-array';
import { area } from 'd3-shape';
import './activityGraph.scss';
import { DataField } from '../../types';

const CURRENT_DATE = new Date();

function ActivityGraph({
  rawData,
  dataFields,
}: {
  rawData: Record<string, unknown>[];
  dataFields: DataField[];
}) {
  const idNum = '1234';
  const [data, setData] = useState<Record<string, unknown>[]>([]);
  const [selectedData, setSelectedData] = useState<Record<string, unknown> | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [maxData, setMaxData] = useState<Record<string, unknown> | undefined>(undefined);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const graphDomElm = document.getElementById(`activity-graph-${idNum}`);
    const holderWidth = graphDomElm!.getBoundingClientRect().width;
    // const isMobile = holderWidth < 700;
    const graphHeight = holderWidth * 0.3;

    var margin = { top: 10, right: 0, bottom: 30, left: 25 },
      width = holderWidth - margin.left - margin.right,
      height = graphHeight - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = select(`#activity-graph-${idNum}`)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('id', `activity-graph-${idNum}-svg`)
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    setWidth(width);
    setHeight(height);
  }, []);

  useEffect(() => {
    if (!rawData.length) return;
    const formattedData = rawData.map(
      rawDataRec =>
        Object.keys(rawDataRec).reduce((obj, key) => {
          const oldVal = rawDataRec[key] as string;
          const val = key === 'date' ? new Date(oldVal) : parseFloat(oldVal);
          return {
            ...obj,
            [key]: val,
          };
        }, {}) as Record<string, unknown>
    );

    const lastRecord = last(formattedData);

    setData([...formattedData, { ...lastRecord, date: CURRENT_DATE }]);
    setMaxData(lastRecord);
  }, [rawData, setData]);

  useEffect(() => {
    if (!data.length) return;

    // delete old items
    document.querySelectorAll('.activity-grid').forEach((e: Element) => e.remove());
    document.querySelectorAll('.activity-path').forEach((e: Element) => e.remove());
    document.querySelectorAll('.mouse-over-effects').forEach((e: Element) => e.remove());

    var svg = select(`#activity-graph-${idNum}-svg`);

    // Add X axis
    var x = scaleTime()
      .domain([first(data).date as Date, CURRENT_DATE])
      .range([0, width]);

    // Add Y axis
    var y = scaleLinear()
      .domain([
        0,
        Math.max.apply(
          null,
          data.map(a =>
            dataFields.reduce((sum, field) => sum + ((a[field.name] || 0) as number), 0)
          )
        ),
      ])
      .range([height, 0]);

    // gridlines in y axis function
    var make_y_gridlines = () => axisLeft(y).ticks(5);
    svg
      .append('g')
      .attr('class', 'grid')
      .call(make_y_gridlines().tickSize(-width).tickFormat(null));

    // gridlines in x axis function
    var make_x_gridlines = () => axisBottom(x).ticks(3);
    svg
      .append('g')
      .attr('class', 'grid activity-grid')
      .attr('transform', 'translate(0,' + (height + 10) + ')')
      .call(make_x_gridlines().tickSize(-width).tickFormat(null));

    dataFields.map((dataField, index) => {
      // const isCorrect = type === 'correct'
      svg
        .append('path')
        .attr('class', 'grid activity-path')
        .datum(data)
        .attr('fill', dataField.color)
        .attr('stroke', dataField.stroke)
        .attr('stroke-width', 1)
        .attr(
          'd',
          area<Record<string, unknown>>()
            .x(d => x(d.date as Date))
            .y0(d => {
              return y(
                dataFields
                  .slice(0, index)
                  .reduce((sum, dataField) => sum + ((d[dataField.name] as number) || 0), 0)
              );
            })
            .y1(function (d) {
              return y(
                dataFields
                  .slice(0, index + 1)
                  .reduce((sum, dataField) => sum + ((d[dataField.name] as number) || 0), 0)
              );
            })
        );
    });

    // Mouse over interaction
    var mouseG = svg.append('g').attr('class', 'mouse-over-effects');

    var bisect = bisector((d: Record<string, unknown>) => d.date).left;

    mouseG
      .append('path') // this is the black vertical line to follow mouse
      .attr('class', 'mouse-line')
      .style('stroke', 'black')
      .style('stroke-width', '1px')
      .style('opacity', '0');

    mouseG
      .append('svg:rect') // append a rect to catch mouse movements on canvas
      .attr('width', width) // can't catch mouse events on a g element
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseout', () => {
        select('.mouse-line').style('opacity', '0');
        setSelectedData(undefined);
        setSelectedDate(undefined);
      })
      .on('mouseover', () => {
        select('.mouse-line').style('opacity', '1');
      })
      .on('mousemove', e => {
        var mouseP = pointer(e, mouseG.node());
        var mouseDate = x.invert(mouseP[0]);
        var xAtDate = x(mouseDate);
        var i = bisect(data, mouseDate);
        setSelectedData(data[Math.max(0, i - 1)]);
        setSelectedDate(mouseDate);
        select('.mouse-line').attr('d', () => `M ${xAtDate} ${0} L ${xAtDate} ${height} z`);
      });
  }, [data, dataFields, height, width, idNum]);

  return (
    <div id={`activity-graph`}>
      <div className='key'>
        {dataFields.map(dataField => (
          <div className='key-item' style={{ borderLeft: `12px solid ${dataField.color}` }}>
            <div className='info' style={{ borderLeft: `1px solid ${dataField.stroke}` }}>
              <div className='title'>{dataField.title}</div>
            </div>
            <div
              className={clsx('number', { active: !!selectedData })}
              style={{ borderLeft: `1px solid ${dataField.stroke}` }}
            >
              {selectedData
                ? (selectedData[dataField.name] as number)
                : (maxData?.[dataField.name] as number)}
            </div>
          </div>
        ))}
        <div className={clsx('date', { active: !!selectedData })}>
          {selectedDate ? selectedDate.toDateString() : 'Present'}
        </div>
      </div>
      <div id={`activity-graph-${idNum}`}></div>
    </div>
  );
}

export default ActivityGraph;
