import React from 'react';
import { Navigate } from 'react-router-dom';
import { useYencilContext } from '../../state/context';

function AuthRoute({ children, authType = 'auth' }: { children: JSX.Element; authType?: string }) {
  const { user, isUserLoaded } = useYencilContext();

  if (!isUserLoaded) return <></>;

  if (authType === 'auth') {
    return user ? children : <Navigate to='/login' />;
  } else {
    return user ? <Navigate to='/' /> : children;
  }
}

export default AuthRoute;
