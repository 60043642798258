export const randomNumber = (): string => {
  return 1000 + Math.round(Math.random() * 8999) + '';
};

export const first = <Type>(array: Type[]): Type => {
  return array[0];
};

export const last = <Type>(array: Type[]): Type => {
  return array[array.length - 1];
};
