import React, { useEffect, useState } from 'react';
import { POST } from '../../services/ajax';
import { useYencilContext } from '../../state/context';
import { DataField, MetricsPage, QueryResponse } from '../../types';
import './graphManager.scss';

type GraphManagerOption = { text: string; value: string };

const categoryDataFields = [
  { name: 'other', color: '#f5f5f5', stroke: '#f5f5f5', title: 'Other' },
  { name: 'real_estate', color: '#e0ffaf', stroke: '#e0ffaf', title: 'Real Estate' },
  { name: 'education', color: '#ffafd7', stroke: '#ffafd7', title: 'Education' },
  { name: 'business', color: '#ffebcd', stroke: '#ffebcd', title: 'Business' },
  { name: 'legal', color: '#ffcdcd', stroke: '#ffcdcd', title: 'Legal' },
  { name: 'finance', color: '#c3f1c3', stroke: '#c3f1c3', title: 'Finance' },
  { name: 'cooking', color: '#cdd6ff', stroke: '#cdd6ff', title: 'Cooking' },
  { name: 'travel', color: '#e2cdff', stroke: '#e2cdff', title: 'Travel' },
  { name: 'health', color: '#cdffff', stroke: '#cdffff', title: 'Health' },
];

const typeDataFields = [
  { name: 'csv', color: '#f5f5f5', stroke: '#f5f5f5', title: 'CSV' },
  { name: 'xlsx', color: '#e0ffaf', stroke: '#e0ffaf', title: 'XSLX' },
  { name: 'xlsm', color: '#ffafd7', stroke: '#ffafd7', title: 'XLSM' },
  { name: 'xls', color: '#ffebcd', stroke: '#ffebcd', title: 'XLS' },
  { name: 'pdf', color: '#ffcdcd', stroke: '#ffcdcd', title: 'PDF' },
  { name: 'doc', color: '#c3f1c3', stroke: '#c3f1c3', title: 'DOC' },
  { name: 'docx', color: '#cdd6ff', stroke: '#cdd6ff', title: 'DOCX' },
  { name: 'ppt', color: '#e2cdff', stroke: '#e2cdff', title: 'PPT' },
  { name: 'pptx', color: '#cdffff', stroke: '#cdffff', title: 'PPTX' },
];

const allOptions: { [k: string]: GraphManagerOption[] } = {
  ambassadors: [{ text: 'Total', value: 'applications_total' }],
  downloads: [
    { text: 'Paid vs. Free', value: 'download_paid_free' },
    { text: 'Category', value: 'download_category' },
    { text: 'Type', value: 'download_type' },
    { text: 'Profit', value: 'download_profit' },
    { text: 'Profit By Category', value: 'downloads_profit_category' },
  ],
  contributions: [
    { text: 'Published vs Unpublished', value: 'contributions_published' },
    { text: 'Category', value: 'contributions_category' },
    { text: 'Type', value: 'contributions_type' },
  ],
  users: [
    { text: 'Total', value: 'users_total' },
    { text: 'Users with Stripe', value: 'users_stripe' },
    { text: 'Users with Contributions', value: 'users_with_contribs' },
  ],
};

const dataFieldsByView: { [k: string]: DataField[] } = {
  applications_total: [{ name: 'count', color: '#ffdf74', stroke: '#f4bc04', title: 'Total' }],
  download_paid_free: [
    { name: 'free', color: '#e1e1e1', stroke: '#ccc', title: 'Free' },
    { name: 'paid', color: '#cce5df', stroke: '#69b3a2', title: 'Paid' },
  ],
  download_category: categoryDataFields,
  download_type: typeDataFields,
  download_profit: [{ name: 'sum', color: '#ffdf74', stroke: '#f4bc04', title: 'Total Profit' }],
  downloads_profit_category: categoryDataFields,
  contributions_published: [
    { name: 'published', color: '#e1e1e1', stroke: '#ccc', title: 'Published' },
    { name: 'unpublished', color: '#cce5df', stroke: '#69b3a2', title: 'Unpublished' },
  ],
  contributions_category: categoryDataFields,
  contributions_type: typeDataFields,
  users_total: [{ name: 'count', color: '#ffdf74', stroke: '#f4bc04', title: 'Total' }],
  users_stripe: [
    { name: 'stripe', color: '#ffdf74', stroke: '#f4bc04', title: 'Stripe' },
    { name: 'no_stripe', color: '#e1e1e1', stroke: '#ccc', title: 'No Stripe' },
  ],
  users_with_contribs: [
    { name: 'uwc', color: '#ffdf74', stroke: '#f4bc04', title: 'Users w/ Contributions' },
    // { name: 'total_users', color: '#e1e1e1', stroke: '#ccc', title: 'Total Users' },
  ],
};

function GraphManager({
  setResults,
  setLoading,
  setDataFields,
  type,
}: {
  setResults: React.Dispatch<Record<string, unknown>[]>;
  setLoading: React.Dispatch<boolean>;
  setDataFields: React.Dispatch<DataField[]>;
  type: MetricsPage;
}) {
  const { isTestMode } = useYencilContext();
  const [total, setTotal] = useState(0);
  const [selectedOption, setSelectedOption] = useState<GraphManagerOption>();
  const [availableOptions, setAvailableOptions] = useState<GraphManagerOption[]>([]);

  useEffect(() => {
    const opts = allOptions[type] || [];
    console.log({ opts });
    setAvailableOptions(opts);
    if (opts.length) {
      setSelectedOption(opts[0]);
    }
  }, [type]);

  useEffect(() => {
    if (!selectedOption) return;
    setLoading(true);
    setDataFields(dataFieldsByView[selectedOption.value]);
    POST(
      `/api/entity/data/fetch`,
      {
        responseType: 'graph',
        eventType: type,
        view: selectedOption.value,
        isTestMode,
      },
      output => {
        const response = output as QueryResponse;
        setTotal(response.metadata.total);
        setResults(response.results);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, [isTestMode, selectedOption, setDataFields, setLoading, setResults, type]);

  return (
    <div id='graph-manager'>
      <div className='title'>
        {total} {type.substring(0, 1).toUpperCase()}
        {type.substring(1)}
      </div>
      <div className='dropdown'>
        <select
          className={'graph-manager-selector'}
          value={selectedOption?.value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const [value] = Array.from(e.target.options)
              .filter(o => o.selected)
              .map(o => o.value);
            const newOption = availableOptions.find(o => o.value === value);
            setSelectedOption(newOption);
          }}
        >
          {availableOptions?.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default GraphManager;
