import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { POST } from '../../services/ajax';
import { useYencilContext } from '../../state/context';
import { DataColumn, MetricsPage, QueryResponse } from '../../types';
import './tableData.scss';

const dataColumnsByType: { [k: string]: DataColumn[] } = {
  ambassadors: [
    { field: 'email', title: 'Email' },
    { field: 'metadata.form.account_id', title: 'User Id' },
    { field: 'metadata.form.data.amb_category', title: 'Category' },
    { field: 'metadata.form.data.amb_tier', title: 'Tier' },
    { field: 'metadata.form.data.amb_website', title: 'Website' },
    { field: 'metadata.form.data.amb_ig', title: 'Instagram' },
    { field: 'metadata.form.data.amb_tik', title: 'TikTok' },
    { field: 'metadata.form.data.amb_face', title: 'Facebook' },
    { field: 'metadata.form.data.amb_twitter', title: 'Twitter' },
    { field: 'metadata.form.data.amb_pin', title: 'Pinterest' },
    { field: 'metadata.form.data.amb_description', title: 'Description', type: 'textarea' },
  ],
  users: [
    { field: 'date', title: 'Date' },
    { field: 'email', title: 'Email' },
    { field: 'metadata.user.first_name', title: 'First Name' },
    { field: 'metadata.user.last_name', title: 'Last Name' },
    { field: 'metadata.user.stripe_connected', title: 'Stripe Connected?' },
  ],
};

function TableData({ type }: { type: MetricsPage }) {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<Record<string, unknown>[]>([]);
  const [dataColumns, setDataColumns] = useState<DataColumn[]>([]);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const { isTestMode } = useYencilContext();

  useEffect(() => {
    setDataColumns(dataColumnsByType[type] || []);
  }, [type]);

  useEffect(() => {
    // if (!selectedOption) return;
    setIsLoading(true);
    // setDataFields(dataFieldsByView[selectedOption.value]);
    if (!dataColumns.length) return;
    POST(
      `/api/entity/data/fetch?sort=date`,
      {
        responseType: 'table',
        eventType: type,
        isTestMode,
      },
      output => {
        const response = output as QueryResponse;
        // setTotal(response.metadata.total);
        setResults(response.results);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [dataColumns, isTestMode, type]);

  const getValue = (record: Record<string, unknown>, col: DataColumn) => {
    const nestedFields = col.field.split('.');
    return nestedFields.reduce(
      (currentVal: Record<string, unknown> | undefined, currentField: string) => {
        if (!currentVal) return currentVal;
        return currentVal[currentField] as Record<string, unknown>;
      },
      record
    );
  };

  const selectField = useCallback(
    (col: DataColumn, record: Record<string, unknown>) => {
      const field = col.field;
      const row = record.id as string;
      if (selectedField === field && selectedRow === row) {
        setSelectedField(null);
        setSelectedRow(null);
      } else {
        setSelectedField(field);
        setSelectedRow(row);
      }
    },
    [selectedField, selectedRow]
  );

  if (!dataColumns.length) return <></>;

  return (
    <div id='table-data'>
      <div className='header'>
        {/* <div className=''>Table Data</div> */}
        <div className='data-row'>
          {dataColumns.map(d => (
            <div className='data-col head'>{d.title}</div>
          ))}
        </div>
      </div>
      {results.map(result => (
        <div className='data-row'>
          {dataColumns.map(d => (
            <div
              className={clsx('data-col data', {
                selected: selectedRow === result.id && selectedField === d.field,
                textarea: d.type === 'textarea',
              })}
              onClick={() => {
                selectField(d, result);
                // setSelectedField(d.field);
                // setSelectedRow(result.id as string);
              }}
            >
              {getValue(result, d)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default TableData;
